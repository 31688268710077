export const reqNutritionist_mealPlans_getDetail = async(axios, { id }) => {
  return axios.get(`/v1/nutritionist/meal-plans/${id}`)
}

export const reqMealPlans_sendToClientManager = async(axios, { id, clientManagerId, note }) => {
  return axios.post(`v1/nutritionist/meal-plans/review/${id}`, {
    clientManagerId: clientManagerId || null,
    note: note || null,
  })
}

export const reqNutritionist_mealPlans_getOptionList = async(axios, { programId }) => {
  return axios.get(`/v1/nutritionist/meal-plans/${programId}/active`)
}

export const reqNutritionist_mealPlans_getClientManager = async(axios) => {
  return axios.get('/v1/nutritionist/meal-plans-client-manager')
}

export const reqNutritionist_mealPlans_preview = async(
  axios, {
    clientId,
    programId,
    month,
  }) => {
  return axios.get('/v1/nutritionist/meal-plans/preview', {
    params: {
      clientId,
      programId,
      month,
    },
  })
}
